.policy,
.terms {
    p {
        margin-top: 5px;
        font-size: 18px !important;
    }

    font-size: 18px !important;

}

header.basic-header {
    position: relative;
    height: 60px;
    padding: 12px 10px;

    img {
        height: 100%;
    }
}