.login-section {
    background-size: auto calc(100vh - 60px);
    background-position: 0 0;
    // min-height: calc(100vh - 60px);
    .login-form {
        background-color: rgb(255, 255, 255);
        float: right;
        zoom: 75%;
        -moz-transform: scale(0.75);
        transform-origin: 50% 0;
        // max-width: 600px;
        width: 100% !important;
        box-shadow: 0px 4px 30px rgba(57, 57, 57, 0.2);
    }
}
