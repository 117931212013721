
@import "react-toastify/dist/ReactToastify.css";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pointer {
  cursor: pointer;
}

.fade:not(.show) {
  opacity: 1;
}